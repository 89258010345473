import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from 'components/Snackbars/Snackbar';
import Box from '@mui/material/Box';
import { closeError } from 'actions/error';
import { styled } from '@mui/system';

const BoxStyled = styled(Box)(() => ({
  borderRadius: 0,
  right: 22,
  bottom: 22,
  position: 'fixed',
  zIndex: 5000,
  maxWidth: 350,
  width: '100%'
}));

const SnackbarWrapper = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state?.error?.list || []);

  const handleClose = React.useCallback(
    (errorIndex) => dispatch(closeError(errorIndex)),
    [dispatch]
  );

  return (
    <BoxStyled>
      {(errors || []).map((error, index) => (
        <Snackbar key={index} error={error} onClose={(e) => handleClose(index)} />
      ))}
    </BoxStyled>
  );
};

export default SnackbarWrapper;
