import dayjs from 'dayjs';
import * as api from 'services/api';
import { addError, addMessage } from 'actions/error';
import storage from 'helpers/storage';
import Message from 'components/Snackbars/Message';
import { ReactComponent as CelebrateIcon } from 'assets/icons/daily/mingcute_celebrate-line.svg';
import { ReactComponent as LightningIcon } from 'assets/icons/daily/icon-park-twotone_lightning.svg';
import { ReactComponent as DashUpdateIcon } from 'assets/icons/daily/dashicons_update-alt.svg';
import { ReactComponent as FireIcon } from 'assets/icons/daily/fluent-emoji-flat_fire.svg';
import { getAchievementIcon } from 'variables/gamification';
import { styled } from '@mui/system';

const IconWrapper = styled('div')({
  '& svg': {
    width: 60,
    height: 60
  }
});

const getDailyStoredData = () => JSON.parse(storage.getItem('triggeredDailyQuest') || '{}');

const checkTriggeredDate = (activeItem) => {
  if (!activeItem) return false;
  const today = dayjs().format('YYYY-MM-DD');
  const triggeredDay = dayjs(activeItem).format('YYYY-MM-DD');
  return triggeredDay === today;
};

const saveTriggeredDate = (completedTask) => {
  const triggered = getDailyStoredData();
  storage.setItem(
    'triggeredDailyQuest',
    JSON.stringify({ ...triggered, [completedTask]: dayjs().format('YYYY-MM-DD') })
  );
};

const removeTriggeredDate = (completedTask) => {
  const triggered = getDailyStoredData();
  delete triggered[completedTask];
  storage.setItem('triggeredDailyQuest', JSON.stringify(triggered));
};

export const getGamificationData = () => (dispatch) => {
  return api
    .get('gamification', 'GET_GAMIFICATION_DATA', dispatch)
    .then((response) => {
      const triggered = getDailyStoredData();
      const transactions = response?.lastTransactions || [];
      const streak = response?.dailyQuestMarathon;
      const achievements = response?.achivements || {};

      const allowedTransactions = transactions.filter(({ actionType }) =>
        ['referFriend'].includes(actionType)
      );

      const dayString = () => {
        if (streak === 1) return 'OneDay';
        if ([2, 3, 4].includes(streak)) return 'TwoDays';
        return 'MoreDays';
      };

      if (streak && !checkTriggeredDate(triggered[`streak_${streak}`])) {
        dispatch(
          addMessage(
            new Message('StreakText', 'dailyQuest', <FireIcon />, {
              count: streak,
              dayString: dayString()
            })
          )
        );
        removeTriggeredDate(`streak_${streak - 1}`);
        saveTriggeredDate(`streak_${streak}`);
      }

      allowedTransactions.forEach(({ actionType, _id }) => {
        if (checkTriggeredDate(triggered[_id])) return;
        dispatch(
          addMessage(
            new Message(
              actionType,
              'dailyQuest',
              <IconWrapper>{getAchievementIcon(actionType)}</IconWrapper>
            )
          )
        );
        saveTriggeredDate(_id);
      });

      Object.keys(achievements).forEach((achievement) => {
        const { level } = achievements[achievement];

        if (!level) return;

        const achievementName = `${achievement}_${level}`;

        const triggered = getDailyStoredData();

        if (triggered[achievementName]) return;

        dispatch(
          addMessage(
            new Message(
              'NewAchievement',
              'dailyQuest',
              <IconWrapper>{getAchievementIcon(achievement)}</IconWrapper>,
              {
                name: achievement,
                level
              }
            )
          )
        );

        saveTriggeredDate(achievementName);
      });

      return response;
    })
    .catch((error) => {
      dispatch(addError(new Error('GET_GAMIFICATION_DATA_ERROR')));
      return error;
    });
};

export const triggerGamification = (completedTask) => (dispatch) =>
  api
    .put('gamification', { completedTask }, 'SET_GAMIFICATION_DATA', dispatch)
    .then((response) => {
      dispatch(addMessage(new Message('EmailSubscribed', 'dailyQuest', <CelebrateIcon />)));
      return response;
    })
    .catch((error) => {
      dispatch(addError(new Error('SET_GAMIFICATION_DATA_ERROR')));
      return error;
    });

export const getDailyQuest = (count) => (dispatch) => {
  return api
    .get('daily-quest', 'GET_DAILY_QUEST', dispatch)
    .then((response) => {
      if (response?.isFinished) {
        const triggered = getDailyStoredData();
        if (checkTriggeredDate(triggered?.finished)) return;
        dispatch(addMessage(new Message('DailyFinished', 'dailyQuest', <CelebrateIcon />)));
        saveTriggeredDate('finished');
      } else {
        if (count) {
          dispatch(
            addMessage(
              new Message('DailyQuestProcessing', 'dailyQuest', <LightningIcon />, {
                count,
                total: 100 - Number(response?.totalPoints || 0)
              })
            )
          );
        }
      }
      return response;
    })
    .catch((error) => {
      dispatch(addError(new Error('GET_DAILY_QUEST_ERROR')));
      return error;
    });
};

export const triggerDailyQuest = (completedTask) => (dispatch) => {
  if (!storage.getItem('token') && completedTask !== 'init') return;

  const triggered = getDailyStoredData();

  const activeItem = triggered[completedTask];

  const isTriggeredToday = checkTriggeredDate(activeItem);

  if (isTriggeredToday && activeItem) return;

  if (completedTask === 'init') {
    saveTriggeredDate(completedTask);
    setTimeout(() => {
      dispatch(addMessage(new Message('DailyUpdated', 'dailyQuest', <DashUpdateIcon />)));
    }, 10000);
    return;
  }

  return api
    .put('daily-quest', { completedTask }, 'TRIGGER_DAILY_QUEST', dispatch)
    .then((response) => {
      saveTriggeredDate(completedTask);
      getDailyQuest(response[`${completedTask}Points`])(dispatch);
      return response;
    })
    .catch((error) => {
      storage.removeItem('triggeredDailyQuest');
      dispatch(addError(new Error('TRIGGER_DAILY_QUEST_ERROR')));
      return error;
    });
};
