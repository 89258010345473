import * as amplitude from '@amplitude/analytics-browser';
import ReactGA4 from 'react-ga4';
import config from 'config';

const {
  amplitudeApiKey,
  gaApiKey,
  application: { environment }
} = config;

class Analytics {
  static init = () => {
    if (environment === 'dev') return;
    Analytics.initAmplitude();
  };

  static initAmplitude = () => {
    amplitude.init(amplitudeApiKey, { serverZone: 'EU', autocapture: true });
  };

  static initGoogle = () => {
    ReactGA4.initialize(gaApiKey);
  };

  static event = (eventName, info = {}) => {
    if (environment === 'dev') return;
    const body = {
      ...info,
      pageUrl: window.location.href
    };

    amplitude.track(eventName, body);

    ReactGA4.event({
      category: 'User',
      action: eventName,
      ...body
    });
  };

  static login = (userId) => {
    if (environment === 'dev') return;
    amplitude.setUserId(userId);
    Analytics.event('LOGIN', userId);
  };

  static logout = (userId) => {
    if (environment === 'dev') return;
    amplitude.reset();
    Analytics.event('LOGOUT', userId);
  };
}

export default Analytics;
